





























































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import FullCalendar from '@fullcalendar/vue';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import esLocale from '@fullcalendar/core/locales/es';
import { appointmentModule } from '@/store/modules/appointment';

interface CalendarEvent {
    id: string;
    title: string;
    start: string;
    hora: string;
    backgroundColor: '#008C0E' | '#3788d8';
    borderColor: '#008C0E' | '#3788d8';
    description: string;
    fechaCompleta: string;
    nombreProyecto: string;
    nombreAgente: string[];
    fullName: string;
    tradename: string;
    estadoCita: string;
    fechaUTF: string;
    comentarioDeLaCita: string;
}

@Component({
    components: { FullCalendar }
})
export default class Calendar extends Vue {
    headerCalendar = {
        left: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth'
    };
    calendarPlugins = [
        interactionPlugin,
        dayGridPlugin,
        timeGridPlugin,
        listPlugin
    ];
    locales = [esLocale];
    formatTime = {
        hour: 'numeric',
        minute: '2-digit',
        meridiem: 'short'
    };
    dialogVisible = false;
    selectedEvent: Partial<CalendarEvent> & {
        textEstadoCita?: string;
    } = {};

    get elements(): CalendarEvent[] {
        return appointmentModule.calendarAppointments.map(element => {
            const classBack =
                element.estadoCita === 'Confirmada' ? '#008C0E' : '#3788d8';
            const [formatstart] = element.fecha.split('.');
            const [customer] = element.customers;
            return {
                id: element._id.CitaModel__id,
                title: customer.fullName,
                start: formatstart,
                hora: element.hora,
                backgroundColor: classBack,
                borderColor: classBack,
                description: element.comentario
                    ? element.comentario
                    : 'Sin Comentarios',
                fechaCompleta: `${element.fechaUTF} - ${element.hora}`,
                nombreProyecto: element.nombreProyecto,
                nombreAgente: element.nombreAgente,
                fullName: customer.fullName,
                tradename: element.tradename,
                estadoCita: element.estadoCita,
                fechaUTF: element.fechaUTF,
                comentarioDeLaCita: element.comentario
                    ? element.comentario
                    : 'Sin Comentarios'
            };
        });
    }

    get titleDialog() {
        return `Cita ${this.selectedEvent.estadoCita}`;
    }

    mounted() {
        appointmentModule.calendar();
    }

    @Watch('elements')
    isEmpty(elements: CalendarEvent[]) {
        if (elements.length < 1) {
            this.$alert(
                'No encontramos citas. Recuerda que puedes solicitar citas desde el buscador de proyectos.',
                'No hay citas',
                { type: 'info', center: true }
            );
        }
    }

    open(event: CalendarEvent) {
        let textEstadoCita = event.estadoCita;
        if (textEstadoCita === 'Agendada') {
            textEstadoCita = 'Solicitada';
        }
        if (textEstadoCita === 'Postergada') {
            textEstadoCita = 'Cancelada';
        }
        this.selectedEvent = { ...event, textEstadoCita };
        this.dialogVisible = true;
    }
}
